<!--  -->
<template>
  <UserCard title="关于系统" :loading="loading">
    <div class="system-info">
      <div class="system-info-item system-info-img">
        <img src="~@/assets/logo-saas.png">
      </div>
      <div class="system-info-item system-info-title">E维SaaS</div>
      <div v-show="version" class="system-info-item system-info-version">V{{ version }}</div>
      <div v-show="time" class="system-info-item system-info-time">更新时间：{{ time }}</div>
    </div>
  </UserCard>
</template>

<script>
import { UserCard } from 'shared/components'

export default {
  name: 'SystemInfo',
  components: { UserCard },
  data() {
    return {
      version: '',
      time: '',
      loading: false
    }
  },
  created() {
    this.getVersionInfo()
  },
  methods: {
    getVersionInfo() {
      this.loading = true
      this.$api.getSaaSVersion().then(res => {
        const { timestamp, version } = res?.data || {}
        this.version = version?.split('-')[0]
        this.time = timestamp?.split(' ')[0].replaceAll('-', '.')
      }).finally(_ => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang='scss' scoped>
$img-width:172px;
$margin-top:136px;

.system-info{
  margin-top: $margin-top;
  font-size: 16px;
  color:$--color-text-primary;
  text-align: center;
  &-item{
    margin-bottom: 16px;
  }
  &-img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 24px;
    width: $img-width;
    height: $img-width;
    border-radius: 12px;
    background: linear-gradient(180deg, #7281FD, #556DF3);
  }
  &-title{
    font-weight: bold;
  }
  &-time{
    font-size: 14px;
    color: #737680;
  }
}
</style>
